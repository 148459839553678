
import { Options, Vue } from 'vue-class-component';

import EventsList from "@/components/EventsList.vue";
import Footer from "@/components/Footer.vue";
import Pattern from "@/components/Pattern.vue";

import Event from "@/types/Event";

@Options({
  components: {
    EventsList,
    Footer,
    Pattern,
  },
})
export default class EventsView extends Vue {

  rendered = false;

  public async mounted(): Promise<void> {
    document.title = "Laurel - Exhibitions";
    await this.store.dispatch('fetchEvents');
    this.rendered = true;
  }

  get upcomingEvents(): Array<Event> {
    return this.store.getters.upcomingEvents;
  }

  get archiveEvents(): Array<Event> {
    return this.store.getters.archiveEvents;
  }
}
