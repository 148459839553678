
import { Options, Vue } from 'vue-class-component';

import Event from "@/types/Event";
import moment from "moment";

@Options({
  props: {
    upcomingEvents: Object as () => Array<Event>,
    archiveEvents: Object as () => Array<Event>,
  }
})
export default class EventsList extends Vue {

  private static dateFormatDay = 'DD';
  private static dateFormatMonth = 'DD.MM';
  private static dateFormatYear = 'DD.MM.YYYY';

  upcomingEvents!: Array<Event>;
  archiveEvents!: Array<Event>;

  private formatOpeningTimes(start: Date, end: Date): string {
    const startDate = moment(start);
    const endDate = end ? moment(end) : null;

    if (!endDate || startDate.isSame(endDate)) {
      return startDate.format(EventsList.dateFormatYear);
    }

    if (startDate.isSame(endDate, 'month')) {
      return `${startDate.format(EventsList.dateFormatDay)} — ${endDate.format(EventsList.dateFormatYear)}`;
    }

    if (startDate.isSame(endDate, 'year')) {
      return `${startDate.format(EventsList.dateFormatMonth)} — ${endDate.format(EventsList.dateFormatYear)}`;
    }

    return `${startDate.format(EventsList.dateFormatYear)} — ${endDate.format(EventsList.dateFormatYear)}`;
  }
}
